import React from "react"
import { HiOutlineMenu } from "react-icons/hi"
import { HiOutlineX } from "react-icons/hi"
import "./navigation.css"

interface Props {
  active: number
}

const Navigation: React.FC<Props> = ({ active = 0 }) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const menuIcon = openMenu ? (
    <HiOutlineX onClick={() => setOpenMenu(false)} size="26px" />
  ) : (
    <HiOutlineMenu onClick={() => setOpenMenu(true)} size="26px" />
  )

  if (active === -1) {
    return (
      <header className="header">
        <div className="logo">
          <a href="/">Ivone Herrera</a>
        </div>
      </header>
    )
  }

  return (
    <header className="header">
      <div className="logo">
        <a href="/">Ivone Herrera</a>
      </div>
      <ul className={`navigation-meun ${openMenu ? "open" : ""}`}>
        <li
          className={`navigation-meun-item front ${
            active === 0 ? "active" : ""
          }`}
        >
          <a href="/">Home</a>
        </li>
        <li
          className={`navigation-meun-item front ${
            active === 2 || active === 3 || active === 4 ? "active" : ""
          }`}
        >
          <a href="#mis-servicios">Servicios</a>
          
        </li>
        <li
          className={`navigation-meun-item front ${
            active === 1 ? "active" : ""
          }`}
        >
          <a href="/sobre-mi">Sobre mí</a>
        </li>
      </ul>
      <div className="burger">{menuIcon}</div>
    </header>
  )
}

export default Navigation
