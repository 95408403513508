import Card, { CardProps } from "./card"
import React from "react"
import "./testimonial.css"
import { BsFillChatSquareQuoteFill } from "react-icons/bs"
import { ImQuotesLeft } from "react-icons/im"
import Image from "../components/image"

interface Props extends CardProps {
  name: string
  variation: "large" | "small"
  image?: string
}

const Testimonial: React.FC<Props> = ({
  children,
  name,
  variation = "large",
  image,
  icon,
  ...rest
}) => {
  const cardIcon =
    icon ||
    (variation === "large" ? (
      <BsFillChatSquareQuoteFill size="40px" />
    ) : (
      <ImQuotesLeft size="20px" />
    ))

  const cardRender = (
    <Card
      className={`testimonial-container ${variation}`}
      icon={cardIcon}
      {...rest}
    >
      <p>{children}</p>
      <div className="name">{name}</div>
    </Card>
  )

  if (!image) return cardRender
  return (
    <div className="testimonial-container-with-image">
      {cardRender}
      <Image src={image} alt={name} />
    </div>
  )
}

export default Testimonial
