import React from "react"
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"

//COMPONENTS
import AboutImage from "./assets/Ivone-Foto5-croped.jpg"
import About from "../components/about"
import Card from "../components/card"
import Navigation from "../components/navigation"
import CtaButton from "../components/cta-button"
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"

//IMAGES
import HeroImage from "./assets/Ivone-Foto5.jpg"
import FotoDanielH from "./assets/Foto_DanielH.jpeg"
import FotoLauraT from "./assets/Foto_LauraT.jpeg"
import FotoGermanM from "./assets/Foto_GermanM.jpeg"
import ImageFotoCoahing1 from "./assets/FotoCoahing1.jpeg"
import ImageSesiones from "./assets/MeSesionesIndividuales.jpeg"
import ImageCoaching from "./assets/FotoCoaching.jpeg"
import ImageEstrategia from "./assets/FotoEstrategia.jpeg"
import ImageSensibilidad from "./assets/FotoSensibilidad.jpeg"
import ImageAccion from "./assets/FotoAction.jpeg"
//import ImageEquipo from "./assets/FotoEquipo.jpeg"
import ImageEquipo from "./assets/FotoIvoneHorizontal.jpeg"

//ICONS
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"

import { BsCheckCircle } from "react-icons/Bs"
import Footer from "../components/footer"

export default function Home() {
  const bulletIcon1 = <IoIosArrowForward color="#92baba" size="36px" />
  const bulletIcon3 = <BsCheckCircle color="#92baba" size="20px" />
  const iconPreguntas = <IoIosArrowDown color="#92baba" size="27px" />
  
  const Testimonio_Daniel1 = `Al comenzar las sesiones con Ivone, me encontraba en una espiral descendente en mi vida. 
  Estaba atrapado en una mentalidad negativa e incapaz de ver ninguna esperanza para el futuro. A esto se sumaba 
  mi falta de motivación para completar las tareas y actividades diarias. A través de su enfoque paciente y empático, 
  Ivone me guió en la re-evaluación de mis valores fundamentales, mis objetivos de vida y mi perspectiva general de 
  la vida. Tambien me enseñó técnicas y enfoques que han sido fundamentales en mi día a día, permitiéndome mantenerme 
  constante y concentrado. Su profesionalismo y empatía me animaron a asumir la responsabilidad de mi propio progreso 
  y "hacer el trabajo” que me corresponde.
  Recomiendo de todo corazón las sesiones personalizadas con Ivone a cualquiera que busque orientación emocional 
  y mental en cualquier etapa de su vida. `
  const TestName_Daniel1 = "Daniel H, Manager"

  const Testimonio_Laura = `Trabajar con Ivone ha sido una experiencia transformadora. 
  Desde el primer momento, me sentí en un ambiente seguro y cómodo para expresar mis inquietudes y 
  desafíos personales. Los ejercicios de introspección me permitieron reflexionar sobre mis 
  valores y creencias, cuestionarme sobre lo que realmente importa en mi vida y guiarme en el descubrimiento 
  de mis propias fortalezas y recursos internos, lo que me permitió adquirir mayor confianza en mi misma a 
  la hora de comunicarme y enfrentarme a nuevos retos.  La recomiendo enormemente a cualquier persona que esté 
  buscando superar ciertos obstáculos en su vida o simplemente mejorar su bienestar emocional. 
  `
  const TestName_Laura = "Laura T, Ingeniera"

  const Testimonio_German1 = `El coaching con Ivone ha sido simplemente constructivo y decisivo! 
  Me ha servido para definir claramente mis deseos en el ámbito profesional, 
  me ha permitido entrar en una etapa de autoreflexión que por muchas razones, 
  no me lo había permitido y estoy seguro que para futuras decisiones 
  tendré mejores bases o argumentos para conseguir lo que yo quiero y necesito. ¡Altamente recomendable!   `
  const TestName_German1 = "German M, Ingeniero"

  return (
    <>
      <Navigation active={0} />
      <HeroSection
        bgImage={HeroImage}
        id="Home"
        subtitle="Asesoría Psicológica, Gestión Emocional, Coaching de Vida e Inteligencia Intercultural en la Migración."
        overlay="part"
        overlayClassName="white flip-h"
        titleClassName="left"
        color="blue1"
        forceAspectRatioOnMobile
        h1="Ivone Herrera"
        overlayColor="rgba(7, 60, 119, 0.65)"
      ></HeroSection>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="lustria blue1 font-size-verylarge lineheight-large"
          title="¿Te identificas con algunas de estas ideas?"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
              ¿Frecuentemente te descubres nostálgica por tu hogar, tu cultura y las relaciones que dejaste atrás, 
              sintiendo que nada puede llenar ese vacío? ¿Sientes que no logras de adaptarte?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Te sientes frustrada o incomprendida al interactuar con personas de otras culturas, 
              a pesar de tus mejores intenciones de comunicarte efectivamente? ¿Te causa ansiedad 
              hablar en otro idioma y cada vez lo intentas menos?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Has notado molestias en tu cuerpo, tensión muscular constante, se acelera tu respiración o vives en un estado 
              de alerta permanente, que te hacen sentir abrumada o inquieta incluso en situaciones cotidianas?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Te sientes abrumada por tus emociones y sin saber por dónde empezar? o ¿Te sientes perdida?
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
              ¿Has experimentado sensaciones de aislamiento o soledad y las barreras culturales hacen que te sientas 
              fuera de lugar?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Sueles enfocarte en tus debilidades o fracasos, te cuesta reconocer tus éxitos y fortalezas, y 
              sientes que eso ha afectado tu autoestima y la confianza en ti misma?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Estas paralizada por las dudas y el miedo de no saber qué hacer o de no saber por dónde empezar? 
              ¿Todas estas dudas te causan ansiedad que va en aumento?
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Te resulta difícil reconocer y expresar lo que realmente sientes, lo que te lleva a sentirte desconectada 
              de ti misma y de los demás y esto esta afectando tu bienestar emocional y tus relaciones personales?
              </BulletItem>

            </Grid>
          </Grid>
          <br />
          <br />
          <div className="center lustria  blue1  font-size-verylarge lineheight-verylarge ">
          Entiendo cómo te sientes porque yo también he estado ahí
            <br />
          </div>
        </Article>
      </Section>

      <Section color="blue1" id="Center-text" >
        
        <Article className="center  bold lustria white font-size-large lineheight-verylarge">
          ¿Sientes dentro de ti esa
          <br />
          necesidad urgente de hacer algo para transformar tu situación,
          <br /> y poder fluir más fácilmente con la vida?
        </Article>
      </Section>

      <Section color="beige1" id="sobre mi">
        <Article layout="wide">
          <About
            titleClassName="lustria bold blue1 font-size-verylarge left lineheight-large"
            title="Soy Ivone Herrera"
            image={AboutImage}
            
          >
            <span className="raleway font-size-normal ">
              Soy mexicana y cuento con más de 10 años de experiencia como
              formadora y facilitadora de Desarrollo Humano. Soy{" "}
              <strong>
                Especialista en Psicoterapia Humanista Gestalt y Coach de Vida
              </strong>{" "}
              y desde 2018 trabajo como profesional independiente, brindando
              cursos, talleres y conferencias especialmente diseñados para
              expats y migrantes.
              <br />
              <br />
              En mi labor, combino la dimensión emocional de la Psicoterapia con
              las herramientas prácticas del Coaching de Vida enfocado al logro
              de objetivos.
              <br />
              <br />
              <span className="bold font-size-normal lineheight-normal">
                Mi misión es brindarte el apoyo que necesitas para superar los
                desafíos emocionales de la vida expat, adaptarte a tu nueva
                cultura sin perder tu identidad y desplegar todo tu potencial en
                tu camino hacia el éxito y la realización personal.
              </span>
              <br />
              <br />
              Por eso te apoyo para que reconozcas y desarrolles tus talentos,
              habilidades y experiencia, personal y profesional, y los puedas
              utilizar como cimientos sólidos para construir tu nueva vida en el
              extranjero.
            </span>
            <br />
            <br />
            <br />
            <span className="lustria center bold blue1 font-size-large lineheight-large">
              ¡Estoy aquí para ser tu aliada, tu confidente y tu apoyo emocional
              en tu vida al otro lado del mundo!
            </span>
            <br />
            <br />
            <CtaButton
              label="Mi Historia y Formación"
              link="/sobre-mi"
            />
          </About>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="lustria blue1 bold font-size-verylarge lineheight-large"
          //title="Conozco muy bien lo qué sientes porque yo también he estado ahí"
          title={<>
            <span>¿CÓMO PUEDO APOYARTE?</span>
            <br />
            <span className="font-size-large">Mis Áreas de Especialidad</span>
            </>}
          subtitleClassName="lustria font-size-normal center"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Adquiere Habilidades para Enfrentar el{" "}
                <strong>Duelo Migratorio </strong>| Procesos Emocionales
                Relacionados con la Migración | <strong>Duelos</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                <strong>Entrenamiento en Inteligencia Intercultural </strong> |
                Identifica como las diferencias interculturales influyen en tus
                Emociones | Mejora tus{" "}
                <strong>relaciones interpersonales y la comunicación </strong>{" "}
                con personas de otras culturas.
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Acontecimientos con <strong>gran impacto emocional</strong> que
                dejan huella | Sensaciones corporales abrumadoras | <strong>Trauma</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                <strong>Estrés Crónico | Ansiedad | Miedos</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                <strong>Gestiona mejor tus Emociones</strong> | Aprende a
                Descifrar su Mensaje y usalas como tu guía o brújula personal.
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
              Desarrolla <strong>Rutinas y Comportamientos</strong> que te
                ayuden a fluir con la vida | Rompe con{" "}
                <strong>Patrones de Comportamiento</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Aprende mejores{" "}
                <strong>Habilidades de Comunicación y Expresión</strong> |
                <strong> Comunicación Asertiva</strong> | Comunicación No
                Violenta
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
              Libera tus <strong>Bloqueos Emocionales</strong>
              </BulletItem>
            </Grid>

            <Grid item md={6}>
            <BulletItem icon={bulletIcon1}>
              <strong>Psicología Positiva</strong> | Reconoce tus{" "}
                <strong>Fortalezas y Talentos</strong> | Desarrolla mayor{" "}
                <strong>Resiliencia </strong> | Identifica qué puedes hacer o dejar de hacer, para sentirte mejor.
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Explora el <strong>Sentido de tu Vida </strong> y Encuentra un{" "}
                <strong> Nuevo Propósito</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Fortalece tu <strong>Confianza</strong> y Mejora tu{" "}
                <strong>Autoestima</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Desarrolla un <strong>Diálogo Interior Positivo</strong>
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Desarrolla nuevas <strong>Creencias y Pensamientos</strong> que
                impulsen tu desarrollo y bienestar | Aprende a gestionar la preocupación y pensamientos negativos repetitivos.
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
              <strong>Reinventate Profesionalmente </strong> | Supera el
                “Sindrome de la Impostora”
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Aprende a proponerte <strong>Metas Realistas</strong> | Crea tu{" "}
                <strong>Plan de Acción</strong> para cumplirlas
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Identifica el origen de tu <strong>Motivación</strong> | Aprende
                como <strong>Mantenerte Motivada</strong>
              </BulletItem>

              
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section
        color="beige"
        //bgImage={ImageOceanBlue}
        // overlay="full"
        // overlayColor="rgba(98, 128, 174,.2)"
      >
        <Article className="center" layout="wide">
          <span className="lustria bold white center font-size-verylarge lineheight-extralarge">
            ¿QUIERES SABER CÓMO FUNCIONA?
          </span>
          <br /> <br />
          <span className="center lustria white font-size-medium">
            {" "}
            Con los tres pilares de mi método: {" "}
          </span>
          <span className="bold lustria white font-size-medium">
            {" "}
            Sensibilidad, Estrategia y Acción{" "}
          </span>
          <br />
          <br />
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                avatar={ImageSensibilidad}
                title="Sensibilidad"
                titleClassName="bold center blue1 lustria font-size-verylarge"
                //className="center pink"
              >
                 <BulletItem icon={bulletIcon3}>
                  {" "}
                  Emplearemos las herramientas de la psicología para explorar tus emociones y desafíos personales,
                  revisaremos en qué áreas necesitas apoyo y cuáles son tus posibilidades de acción y desarrollo.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Aprenderás a reconocer y comprender mejor tus emociones, lo que te permitirá 
                  tomar decisiones más alineadas con lo que de verdad es importante para ti y tus objetivos personales.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>

              <Card
                avatar={ImageEstrategia}
                title="Estrategia"
                titleClassName="bold center blue1 lustria font-size-verylarge"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Identificarás las acciones y comportamientos concretos que te permitirán experimentar los cambios que estas buscando.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Identificarás lo que es importante para ti, tus necesidades personales y lo que quieres en tu vida.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te ayudaré a desarrollar las habilidades y la mentalidad que te permitirán avanzar hacia tus metas.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              {/* <Card icon={icon} title="Acción" className="center"> */}
              <Card
                avatar={ImageAccion}
                title="Acción"
                titleClassName="bold center blue1 lustria font-size-verylarge"
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Pondrás en práctica las estrategias que has aprendido y
                  reajustarás tus acciones de manera consciente.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Exploraremos tus emociones para identificar si vas por el camino correcto para ti, y aprenderás a reajustar el rumbo de ser necesario.
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Desarrollaras mayor confianza y flexibilidad para enfrentarte a nuevos retos.
                  {" "}
                </BulletItem>
                
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>


      <Section color="blue1">
        <Article layout="wide">
        
          <About 
           titleClassName="lustria bold blue1 font-size-verylarge lineheight-large"
          title="¡Somos un Equipo!" image={ImageEquipo}>
          
          <BulletItem icon={bulletIcon3}>
          {" "}
          Soy tu confidente y la voz de apoyo que necesitas para surfear
          los desafíos que se te presentan.
          {" "}
          </BulletItem>
          <BulletItem icon={bulletIcon3}>
          {" "}
           Te brindaré el respaldo y la motivación que necesitas hasta que te sientas segura y confiada para continuar por ti misma!
          {" "}
          </BulletItem>
          <BulletItem icon={bulletIcon3}>
          {" "}
          ¡Toda mi experiencia y formación profesional están al servicio de tu desarrollo!
          {" "}
          </BulletItem>
          

          </About>
        </Article>
      </Section>


      {/* <Section>
          <Article className="center">
          <div  className="bold playfair center font-size-large blue1 lineheight-large">
          ¿Tienes un problema en particular y quieres saber si te puedo ayudar?
          </div>
          <div className="raleway center font-size-small lineheight-large" >
          <br/>
          Te entiendo. Hablando es más sencillo.
          <br/>
          Por eso, charlemos para que puedas explicarme 
          <br/>
          qué es lo que sientes y lo que necesitas.
          </div>

          <br/>
          <div className="pink">
          VS escribeme un email / envíame un Whatsapp >> Verificar humanidad primero!!!
          <br/>
          Re-direcciona a la agenda y se EXPLICA lo que esta sesión NO es!! 
          </div>
          <CtaButton label="Agendar una Sesión de Descubrimiento" />
          </Article>
      </Section> */}

      

      <Section color="white">
        <Article layout="narrow" 
                  titleClassName="lustria bold blue1 font-size-verylarge  lineheight-large"
        title="Ell@s ya saben lo que es trabajar conmigo">
          <Testimonial name={TestName_Daniel1} variation="small" image={FotoDanielH}>
          {" "}
              {Testimonio_Daniel1}
          </Testimonial>
          <Testimonial name={TestName_Laura} variation="small" image={FotoLauraT}>
          {" "}
              {Testimonio_Laura}
          </Testimonial>
          <Testimonial name={TestName_German1}  variation="small" image={FotoGermanM}>
          {" "}
              {Testimonio_German1}
          </Testimonial>
          
        </Article>
      </Section>






      <Section color="beige1" id="mis-servicios">
        <Article
          layout="wide"
          titleClassName="blue1 lustria font-size-verylarge lineheight-large"
          title={<>
            <span>DESCUBRE CÓMO PODEMOS TRABAJAR JUNT@S</span>
            <br />
            <span className="font-size-large">Mis Servicios</span>
            </>}
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                //link="/proximamente"
                avatar={ImageSesiones}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Asesoría Psicológica Individual
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Exploramos distintas áreas de tu vida y la forma en que diversas experiencias de tu historia 
                personal influyen en tu presente. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Empleamos las técnicas de la psicología para explorar y 
                comprender mejor tus emociones, identificar tus desafíos personales. Te brindo estrategias para 
                superarlos.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Es un proceso altamente personalizado y de acuerdo a tus necesidades que comprende 
                la mente, las emociones y el cuerpo.
                  {" "}
                </BulletItem>
                
                <div className="center  ">
                Sesiones de 60 min. <br /> Adultos, mujeres y hombres. 
                </div>

              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
            <Card
                //link="/curso-amplifica-tu-confianza"
                avatar={ImageCoaching}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Life Coaching | Coaching de Vida
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te apoyo para que alcances tus metas e incrementes tu bienestar. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Juntas exploraremos tus fortalezas, deseos y desafíos personales. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te proporcionaré herramientas para fortalecer tu resiliencia y
                lograr un equilibrio más pleno en tu vida. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te ayudo a definir con claridad tus metas y el plan 
                que necesitas para alcanzarlas.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  ¡Transformaras tu vida de manera positiva!
                  {" "}
                </BulletItem>

                <div className="center  ">
                Sesiones de 60 min. <br /> Adultos, mujeres y hombres. 
                </div>  
              </Card>
            </Grid>

            <Grid item md={4} xs={10}>
              <Card
                //link="/curso-amplifica-tu-confianza"
                avatar={ImageFotoCoahing1}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Programas Personalizados
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Diseño un programa adaptado a tus desafíos y objetivos personales.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Nos enfocamos en desarrollar las habilidades que necesitas para alcanzar tus metas. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Desarrollo charlas, cursos y talleres para adultos según necesidades y objetivos específicos.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Si ya tienes un objetivo en mente, consulta conmigo para saber más sobre cómo podemos trabajar juntas.
                  {" "}
                </BulletItem>
                <br /> 
                <div className="center  ">
                Programas individuales y grupales <br /> 
                Duración según objetivos
                </div>

              </Card>
            </Grid>
            
          </Grid>
        </Article>
      </Section>


      <Section color="blue1">
        <Article
          layout="narrow"
          titleClassName="lustria white bold font-size-extralarge lineheight-verylarge"
          title="Si todavía tienes dudas... "
        >
          <Card>
            <BulletItem icon={bulletIcon1}>
              Te ofrezco una <strong> Llamada de Valoración Sin Costo de 20 min </strong> para
              conocer un poco de ti, saber en qué áreas necesitas apoyo e
              <strong> identificar si sientes afinidad y confianza para trabajar conmigo.</strong>
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Durante la llamada aclaro todas tus dudas e inquietudes y
              comentamos de qué modo te puedo apoyar específicamente.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Por respeto al tiempo de ambas, te pido que seas puntual y en caso
              de no poder asistir a la llamada, te agradeceré me lo hagas saber
              a la brevedad posible.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              ¡Escríbeme un mensaje de <a href="https://wa.me/4915906745786?text=Hola!%20Ya%20revisé%20tu%20Web,%20y%20me%20gustaría%20agendar%20una%20Llamada%20de%20Valoración%20contigo." target="_blank" rel="noreferrer">Whatsapp</a> para acordar la fecha y hora de nuestra llamada!
            </BulletItem>
          </Card>
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="narrow"
          titleClassName="lustria blue1 bold font-size-extralarge lineheight-verylarge"

          title="Preguntas Frecuentes"
        >
          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
            ¿Cuánto tiempo debo dedicarle?
            </AccordionSummary>
            <AccordionDetails>
            Para brindarte el mejor apoyo posible recomiendo que iniciemos con sesiones semanales. 
            Esto ritmo nos dará la oportunidad de conocernos mejor y establecer una relación en la que 
            te sientas en confianza para ser tu misma y expresar tus inquietudes libremente. 
            Considera estas sesiones como un compromiso contigo misma y una inversión vital en tu bienestar emocional.
            A medida que avancemos, sentirás el progreso y determinarás naturalmente cuando es el momento adecuado 
            para espaciar nuestras reuniones. 
            Mi labor es brindarte nuevas herramientas y estrategias que, 
            sumadas a tus experiencias y fortalezas personales, te permitirán seguir adelante, con mayor confianza y autonomía.
            </AccordionDetails>
          </Accordion>
          
          <Accordion>
            <AccordionSummary className="bold " expandIcon={iconPreguntas}>
              ¿Trabajas con Aseguradoras?
            </AccordionSummary>
            <AccordionDetails>
              Me enfoco en brindar un servicio completamente personalizado,
              diseñado especialmente para ti y tus necesidades únicas de acuerdo
              a mi especialización en el enfoque Humanista Gestalt. Esta manera
              de trabajar me permite ofrecerte una atención flexible y
              totalmente centrada en tus necesidades, lo que por el momento, no
              coincide con los métodos de las aseguradoras. Pero quiero que
              sepas que estoy plenamente comprometida a encontrar opciones que
              sean accesibles y asequibles para ti, para asegurarme de que
              recibas el mejor apoyo posible.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary className="bold" expandIcon={iconPreguntas}>
              ¿Cómo se realiza el pago?
            </AccordionSummary>
            <AccordionDetails>
              Puedes realizar el pago de las sesiones de forma privada y segura
              mediante PayPal o transferencia bancaria. Una vez que programemos
              nuestra sesión, te facilitaré todos los detalles necesarios para
              que elijas el método que te sea más conveniente. El pago debe
              efectuarse con una semana de anticipación a la sesión.
            </AccordionDetails>
          </Accordion>
          

        </Article>
      </Section>

      <Footer />
    </>
  )
}
