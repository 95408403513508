import React from "react"
import "./about.css"

interface Props {
  image?: string
  title?: string
  className?: string
  titleClassName?: string
}

const About: React.FC<Props> = ({
  children,
  title,
  image,
  titleClassName = "",
  className = "",
}) => (
  <div className={`about-container ${className}`}>
    {title && <h2 className={titleClassName}>{title}</h2>}
    <div className="about-content-container">
      <div className="about-content">{children}</div>
      <div className="about-image">
        {image && <img src={image} alt={image} />}
      </div>
    </div>
  </div>
)

export default About
