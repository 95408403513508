import React from "react"
import "./bullet-item.css"

interface Props {
  icon?: React.ReactElement
}

const BulletItem: React.FC<Props> = ({ children, icon }) => (
  <div className="bullet-item-card">
    <div className="bullet-item-icon">{icon}</div>
    <div className="bullet-item-content">{children}</div>
  </div>
)

export default BulletItem
