import { Button } from "@material-ui/core"
import React from "react"
import "./cta-button.css"

interface Props {
  label: string
  className?: string
  disabled?: boolean
  link?: string
  onClick?: () => {}
}

const CtaButton: React.FC<Props> = ({
  label,
  className = "",
  disabled = false,
  link = "#",
  onClick,
}) => (
  <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    className={`cta-button ${className}`}
    href={link}
    onClick={onClick}
  >
    {label}
  </Button>
)

export default CtaButton
