import React from "react"
import "./image.css"

interface Props {
  src: string
  alt?: string
}

const Image: React.FC<Props> = ({ src, alt }) => (
  <img className="image" src={src} alt={alt || src} />
)

export default Image
