import React from "react"
import { Color, getBgColorStyle } from "../utils/color"
import "./hero-section.css"

type Overlay = "full" | "part" | "none"

interface SectionProps {
  color: Color | string
  overlayColor?: string // rgba
  bgImage?: string
  overlay?: Overlay
  withTextOverlay: boolean
  h1?: string
  subtitle?: string
  titleClassName?: string
  overlayClassName?: string
  forceAspectRatioOnMobile?: boolean
}

const HeroSection: React.FC<SectionProps> = ({
  children,
  color = "white",
  overlayColor = "rgba(0, 0, 0, 0.5)",
  bgImage,
  overlay = "none",
  titleClassName = "",
  overlayClassName = "",
  forceAspectRatioOnMobile = false,
  h1,
  subtitle,
}) => {
  const bgImageStyle = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
      }
    : {}
  const bgColorStyle = getBgColorStyle(color)

  return (
    <section
      className={`hero-section ${bgImage ? "bg" : "content"} ${
        forceAspectRatioOnMobile ? "aspect-ratio" : ""
      }`}
      style={{ ...bgImageStyle, ...bgColorStyle }}
    >
      {overlay !== "none" ? (
        <div
          className={`overlay ${overlay} ${overlayClassName}`}
          style={{ backgroundColor: overlayColor }}
        >
          {h1 ? (
            <h1 className={`headline1 ${titleClassName}`}>{h1}</h1>
          ) : (
            children
          )}
          {subtitle && <div className="overlay-subtitle">{subtitle}</div>}
        </div>
      ) : (
        children
      )}
    </section>
  )
}

export default HeroSection
